.sidenavPro {
  height: 0%;
  width: 100%;
  min-height: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: #FFFFFF;
  overflow: scroll;
  overflow-x: hidden;
  transition: 0.5s;
}

@media screen and (min-width: 991px) {
  .productDropdown {
    max-height: 100vh;
  }
}
